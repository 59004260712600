.loader div {
	animation-duration: 0.5s;
}

.loader div:first-child {
	animation-delay: 0.1s;
}

.loader div:nth-child(2) {
	animation-delay: 0.2s;
}

.loader div:nth-child(3) {
	animation-delay: 0.3s;
}
